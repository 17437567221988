<template>
  <div>
    <vs-row>
      <vs-col vs-w="12" class="mt-6">
        <section v-if="staffRole.name" class="staff-role-name ml-6 mb-4">
          <div v-if="!editStaffRoleName" class="staff-role-name__display">
            <h4 class="mb-0" @click="toggleStaffRoleNameEdit">
              {{ staffRoleInEdit.name }}
            </h4>
            <feather-icon
              v-tooltip="lang.staffRoles.editRoleName[languageSelected]"
              icon="EditIcon"
              class="ml-4 cursor-pointer"
              @click="toggleStaffRoleNameEdit"
            />
          </div>
          <div v-else class="staff-role-name__edit">
            <vs-input
              :placeholder="staffRoleInEdit.name"
              v-model="newStaffRoleName"
              v-focus
              @keyup.enter="saveStaffRoleName"
              @keyup.esc="cancelStaffRoleName"
              @blur="onBlur"
            />
            <div @mousedown.prevent="saveStaffRoleName">
              <feather-icon icon="CheckIcon" class="ml-4 cursor-pointer" />
            </div>
            <div @mousedown.prevent="cancelStaffRoleName">
              <feather-icon icon="XIcon" class="ml-4 cursor-pointer" />
            </div>
          </div>
        </section>
      </vs-col>
      <vs-divider />
      <vs-col vs-xs="12" vs-sm="12" vs-lg="5" class="mb-base">
        <vs-row class="vx-row pl-6">
          <vs-col vs-w="12" class="pr-0">
            <h6 class="mt-3 mb-6 float-left">
              <vs-icon icon="icon-lock" icon-pack="feather" />
              {{ lang.staffRoles.tableHeaders.permissions[languageSelected] }}
              <strong
                ><small
                  >({{ permissionsCount }}/{{ staffPermissions.length }})</small
                ></strong
              >
            </h6>
            <a
              class="mt-base float-right"
              style="cursor: pointer;"
              @click="toggleAllPermissions"
            >
              <vs-icon
                :icon="
                  permissionsCount === staffPermissions.length
                    ? 'icon-check-square'
                    : 'icon-square'
                "
                icon-pack="feather"
              />
              {{
                permissionsCount === staffPermissions.length
                  ? lang.staffRoles.uncheckAll[languageSelected]
                  : lang.staffRoles.checkAll[languageSelected]
              }}
            </a>
          </vs-col>
        </vs-row>

        <vs-collapse accordion>
          <vs-collapse-item
            :key="`${permissionsGroup.prefix}${indextr}`"
            v-for="(permissionsGroup, indextr) in staffPermissionsByGroup"
          >
            <div slot="header">
              {{
                permissionsGroup.categoryLabel[languageSelected] ||
                  permissionsGroup.categoryLabel.en
              }}
              <small
                :class="
                  permissionsGroup.permissions.filter(item => item.check)
                    .length === permissionsGroup.permissions.length
                    ? 'text-success'
                    : permissionsGroup.permissions.filter(item => item.check)
                        .length !== 0
                    ? 'text-primary'
                    : ''
                "
                ><strong>
                  ({{
                    permissionsGroup.permissions.filter(item => item.check)
                      .length
                  }}/{{ permissionsGroup.permissions.length }})
                </strong>
              </small>
            </div>
            <ul class="roles">
              <li
                :key="`${permission._id}${index2}`"
                v-for="(permission, index2) in permissionsGroup.permissions"
                class="mb-4"
              >
                <label for="">{{
                  permission.label[languageSelected] || permission.label.en
                }}</label>
                <vx-slider
                  v-model="staffPermissionsByGroup[indextr].permissions[index2].check"
                  :vs-value="permission.name"
                  :name="permission.name"
                />
              </li>
            </ul>
          </vs-collapse-item>
        </vs-collapse>
      </vs-col>
      <vs-col vs-xs="12" vs-sm="12" vs-lg="7">
        <h6 class="ml-2 mt-3">
          <vs-icon icon="icon-user" icon-pack="feather" />
          {{ lang.staffRoles.tableHeaders.users[languageSelected] }}
          <strong
            ><small>({{ staffUsers.length }}/{{ users.length }})</small></strong
          >
        </h6>
        <vs-row class="vx-row">
          <vs-col vs-w="12" class="pr-0">
            <select-users
              ref="selectUsersRef"
              :onlyAgents="false"
              :usersSelected="staffUsers"
              :selectUsers="selectUsers"
            />
            <vs-button
              color="primary"
              type="filled"
              icon="book"
              class="float-right"
              @click="toggleIsSelectUsersOpen"
            >
              {{ lang.staffRoles.manageUsers.button[languageSelected] }}
            </vs-button>
          </vs-col>
        </vs-row>

        <vs-table
          pagination
          :data="staffUsers"
          :max-items="5"
          :search="true"
          :hoverFlat="true"
          :no-data-text="lang.general.noDataText[languageSelected]"
        >
          <template slot="thead">
            <vs-th sortKey="name">{{
              lang.botMaker.addBot.manageUsers.table.name[languageSelected]
            }}</vs-th>
            <vs-th sortKey="email">{{
              lang.botMaker.addBot.manageUsers.table.email[languageSelected]
            }}</vs-th>
            <vs-th></vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.name">{{ tr.name }}</vs-td>
              <vs-td :data="tr.email">{{ tr.email }}</vs-td>
              <vs-td>
                <div class="action-buttons">
                  <vs-button
                    type="flat"
                    @click="deleteUser(tr._id)"
                    icon-pack="feather"
                    icon="icon-x"
                    color="warning"
                  ></vs-button>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-row class="vx-row mb-asd">
          <vs-col vs-w="12" class="pr-0">
            <h6 class="ml-2 mt-3">
              <vs-icon icon="icon-clock" icon-pack="feather" />
              {{ lang.staffRoles.passwordExpiration[languageSelected] }}
            </h6>
            <vs-select
              v-model="passwordExpiration"
              autocomplete
              class="ml-2 mt-4 w-1/2"
            >
              <vs-select-item
                :key="key"
                :value="pe.milliseconds"
                :text="pe.text"
                v-for="(pe, key) in passwordExpirationsComputed"
              />
            </vs-select>
          </vs-col>
        </vs-row>
        <vs-divider />
        <vs-row class="vx-row mb-base">
          <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end" class="pr-0">
            <vs-button
              color="danger"
              type="flat"
              class="mr-4"
              @click.prevent="$router.push('/roles')"
            >
              {{ lang.staffRoles.cancel[languageSelected] }}
            </vs-button>
            <vs-button @click="onSubmit">
              <span v-if="isEditing">{{
                lang.staffRoles.editTitle[languageSelected]
              }}</span>
              <span v-else>{{
                lang.staffRoles.addTitle[languageSelected]
              }}</span>
            </vs-button>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SelectUsers from './SelectUsers'

import StaffManagementService from '../../../../services/staffManagement.service'

export default {
  name: 'AddStaffRoleForm',
  components: {
    SelectUsers
  },
  props: {
    currentStaffRole: {
      Type: Object
    },
    staffPermissions: {
      Type: Array
    },
    users: {
      Type: Array
    }
  },
  data() {
    return {
      passwordExpiration: null,
      passwordExpirations: [
        {
          milliseconds: 0,
          es: 'Nunca',
          en: 'Never',
          pt: 'Nunca'
        },
        { milliseconds: 604800000 },
        { milliseconds: 1209600000 },
        { milliseconds: 1814400000 },
        { milliseconds: 2592000000 },
        { milliseconds: 5184000000 },
        { milliseconds: 7776000000 },
        { milliseconds: 15552000000 },
        { milliseconds: 31536000000 }
      ],

      isEditing: false,
      staffRole: {},

      editStaffRoleName: false,
      newStaffRoleName: '',

      staffPermissionsByGroup: [],
      staffUsers: [],

      isSelectUsersOpen: false,
      staffRoleInEdit: null
    }
  },
  created() {
    this.staffRoleInEdit = this.staffRole
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session']),
    passwordExpirationsComputed() {
      return this.passwordExpirations.map(pe =>
        pe[this.languageSelected]
          ? { text: pe[this.languageSelected], milliseconds: pe.milliseconds }
          : {
              text: this.$moment.duration(pe.milliseconds).humanize(true),
              milliseconds: pe.milliseconds
            }
      )
    },
    permissionsCount() {
      let count = 0
      this.staffPermissionsByGroup.forEach(item => {
        count += item.permissions.filter(element => element.check).length
      })
      return count
    }
  },
  methods: {
    errorToast() {
      this.$vs.notify({
        title: this.lang.staffRoles.genericError.title[this.languageSelected],
        text: this.lang.staffRoles.genericError.text[this.languageSelected],
        color: 'danger'
      })
    },
    successToast() {
      this.$vs.notify({
        title: this.lang.staffRoles.genericSuccess.title[this.languageSelected],
        text: this.lang.staffRoles.genericSuccess.text[this.languageSelected],
        color: 'success'
      })
    },
    toggleAllPermissions() {
      let newValue = true
      if (this.permissionsCount === this.staffPermissions.length) {
        newValue = false
      }
      this.staffPermissionsByGroup.forEach(item => {
        item.permissions.forEach(element => {
          element.check = newValue
        })
      })
    },
    getPayload() {
      return {
        permissions: this.staffPermissionsByGroup.reduce((acc, elem) => {
          elem.permissions.forEach(permission => {
            if (permission.check) {
              acc.push(permission._id)
            }
          })
          return acc
        }, []),
        users: this.staffUsers.map(item => item._id),
        passwordExpiration: this.passwordExpiration,
        name: this.staffRole.name,
      }
    },
    deleteUser(userId) {
      const index = this.staffUsers.findIndex(item => item._id === userId)
      if (index > -1) {
        this.staffUsers.splice(index, 1)
      }
    },
    selectUsers(users) {
      this.staffUsers = this.staffUsers.concat(
        users.map(item => ({
          ...item,
          _id: item.id
        }))
      )
    },
    toggleIsSelectUsersOpen() {
      this.$refs.selectUsersRef.setOpenValue(true)
    },
    async saveStaffRoleName() {
      if (!this.newStaffRoleName || this.newStaffRoleName.trim().length === 0) {
        this.newStaffRoleName = this.staffRole.name
        return this.toggleStaffRoleNameEdit()
      }
      if (this.newStaffRoleName.length > 15) {
        this.$vs.notify({
          title: this.lang.staffRoles.nameTooLong.title[this.languageSelected],
          text: this.lang.staffRoles.nameTooLong.text[this.languageSelected],
          color: 'danger'
        })
        return this.toggleStaffRoleNameEdit()
      }
      this.$vs.loading()

      if (this.staffRole._id) {
        // edit
        try {
          await StaffManagementService.updateStaffRole(this.staffRole._id, {
            ...this.getPayload(),
            name: this.newStaffRoleName
          })
          this.staffRole.name = this.newStaffRoleName
          this.successToast()
        } catch (ex) {
          console.error(ex)
          this.errorToast()
          this.newStaffRoleName = this.staffRole.name
        }
      } else {
        // new role
        try {
          const { data } = await StaffManagementService.createStaffRole({
            ...this.getPayload(),
            name: this.newStaffRoleName
          })
          this.$router.push({ path: `/roles/${data._id}/edit` })
          this.successToast()
        } catch (ex) {
          console.error(ex)
          this.errorToast()
          this.newStaffRoleName = this.staffRole.name
        }
      }

      this.toggleStaffRoleNameEdit()
      this.$vs.loading.close()
    },
    cancelStaffRoleName() {
      this.toggleStaffRoleNameEdit()
      this.newStaffRoleName = ''
    },
    onBlur() {
      this.editStaffRoleName = false
      this.newStaffRoleName = ''
    },
    toggleStaffRoleNameEdit() {
      this.editStaffRoleName = !this.editStaffRoleName
      if (this.editStaffRoleName) {
        this.newStaffRoleName = this.staffRoleInEdit.name
      }
    },
    emitSurveyValue(survey) {
      this.surveyValues.survey = survey._id
    },
    async onSubmit() {
      if (this.isEditing) {
        this.updateStaffRole()
      } else {
        this.createStaffRole()
      }
    },
    async updateStaffRole() {
      this.$vs.loading()
      try {
        await StaffManagementService.updateStaffRole(
          this.staffRole._id,
          this.getPayload()
        )
        this.successToast()
      } catch (ex) {
        console.error(ex)
        this.errorToast()
      }
      this.$vs.loading.close()
    },
    async createStaffRole() {
      this.$vs.loading()
      try {
        const { data } = await StaffManagementService.createStaffRole({
          ...this.getPayload(),
          name: this.staffRole.name
        })
        this.$router.push({ path: `/roles/${data._id}/edit` })
        this.successToast()
      } catch (ex) {
        console.error(ex)
        this.errorToast()
      }
      this.$vs.loading.close()
    }
  },
  mounted() {
    this.staffRole = this.currentStaffRole
    this.staffRoleInEdit = this.staffRole
    this.staffPermissionsByGroup = []
    this.staffPermissions.forEach(item => {
      const prefix = item.name.split('.')[1]
      const index = this.staffPermissionsByGroup.findIndex(
        item => item.prefix === prefix
      )
      if (index === -1) {
        this.staffPermissionsByGroup.push({
          prefix,
          categoryLabel: item.categoryLabel,
          permissions: [
            {
              ...item,
              check: this.staffRole.permissions.some(p => p === item._id)
            }
          ]
        })
      } else {
        this.staffPermissionsByGroup[index].permissions.push({
          ...item,
          check: this.staffRole.permissions.some(p => p === item._id)
        })
      }
    })
    this.staffPermissionsByGroup.sort((a, b) =>
      a.prefix > b.prefix ? 1 : b.prefix > a.prefix ? -1 : 0
    )
    this.staffUsers = this.staffRole.users
      .map(userId => {
        return this.users.find(user => user._id === userId)
      })
      .filter(item => item)
    const staffRoleId = this.$route.params.staffRoleId
    if (staffRoleId) {
      this.isEditing = true
    }
    this.passwordExpiration = this.staffRole.passwordExpiration || 0
  }
}
</script>

<style scoped lang="scss">
.staff-role-name {
  &__display,
  &__edit {
    display: flex;
    flex-direction: row;
    align-items: center;

    .feather-icon {
      width: 14px;
    }
  }
}
.action-buttons {
  display: flex;
  justify-content: flex-end;
}
</style>
