<template>
  <vs-card id="add-user">
    <div slot="header">
      <h3 v-if="isEditing">
        {{ lang.staffRoles.editTitle[languageSelected] }}
      </h3>
      <h3 v-else>{{ lang.staffRoles.addTitle[languageSelected] }}</h3>
    </div>
    <vs-row>
      <vs-col vs-w="12">
        <add-staff-role-form
          v-if="loaded"
          :currentStaffRole="staffRole"
          :staffPermissions="staffPermissions"
          :users="users"
        />
      </vs-col>
    </vs-row>
  </vs-card>
</template>

<script>
import AddStaffRoleForm from './components/AddStaffRoleForm'
import { mapState } from 'vuex'

import StaffManagementService from '../../../services/staffManagement.service'
import BotMakerService from '../../../services/botMaker.service'

export default {
  name: 'AddStaffRole',
  components: { AddStaffRoleForm },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    isEditing() {
      return !!this.$route.params.staffRoleId
    }
  },
  data() {
    return {
      staffPermissions: [],
      users: [],
      staffRole: {
        name: 'Role Name',
        permissions: [],
        users: []
      },
      loaded: false
    }
  },
  methods: {
    async load() {
      this.$vs.loading()
      try {
        this.staffPermissions = (await StaffManagementService.getStaffPermissions()).data
        this.users = (await BotMakerService.getUsers()).data
        if (this.isEditing && this.$route.params.staffRoleId) {
          this.staffRole = (await StaffManagementService.getStaffRole(
            this.$route.params.staffRoleId
          )).data
        }
        this.loaded = true
        this.$vs.loading.close()
        this.loaded = true
      } catch (ex) {
        console.errror(ex)
        this.$vs.loading.close()
        this.errorToast()
      }
    },
    errorToast() {
      this.$vs.notify({
        title: this.lang.staffRoles.genericError.title[this.languageSelected],
        text: this.lang.staffRoles.genericError.text[this.languageSelected],
        color: 'danger'
      })
    }
  },
  mounted() {
    this.load()
  }
}
</script>

<style lang="scss" scoped>
#add-user {
  .add-user-container {
    margin: 30px auto;
    .vs-con-input-label {
      width: 100%;
    }
    .roles {
      li {
        padding: 5px 0;
      }
    }
  }
  .error-message {
    padding: 2px 4px;
    display: block;
    height: 19px;
    color: rgba(var(--vs-danger), 1);
    font-size: 0.65rem;
  }
}
</style>
